<script lang="ts">
	export let height = '48px' // Default height
	export let width = '48px' // Default width

	let className = ''
	export { className as class }
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	><defs />
	<path
		d="M34 29.08v3.8c3.656 0.734 6 1.856 6 3.12 0 2.212 -7.162 4 -16 4s-16 -1.788 -16 -4c0 -1.264 2.346 -2.386 6 -3.12v-3.8c-5.972 1.382 -10 3.96 -10 6.92 0 4.42 8.954 8 20 8s20 -3.58 20 -8c0 -2.96 -4.028 -5.538 -10 -6.92z"
		fill="currentColor"
		stroke-width="2"
	/>
	<path
		d="M36 16A12 12 0 0 0 12 16c0 8 12 22 12 22s12 -14 12 -22zm-16 0a4 4 0 1 1 4 4 4 4 0 0 1 -4 -4z"
		fill="currentColor"
		stroke-width="2"
	/></svg
>
