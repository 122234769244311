<script lang="ts">
	export let height = '48px' // Default height
	export let width = '48px' // Default width
	let className = ''
	export { className as class }
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	><defs /><path
		d="M14 28v-8a2 2 0 0 1 2 -2h14V6a2 2 0 0 0 -2 -2H6a2 2 0 0 0 -2 2v36a2 2 0 0 0 2 2h22a2 2 0 0 0 2 -2v-12H16a2 2 0 0 1 -2 -2z"
		fill="currentColor"
		stroke-width="2"
	/><path
		d="m37.414 14.586 -2.828 2.828L39.172 22H18v4h21.172l-4.586 4.586 2.828 2.828L46.828 24l-9.414 -9.414z"
		fill="currentColor"
		stroke-width="2"
	/></svg
>
