<script lang="ts">
	export let activeColor = '#000000' // Default color
	export let isActive: boolean = false
	export let color = '#000000' // Default color
	export let height = '48px' // Default height
	export let width = '48px' // Default width
	let className = ''
	export { className as class }
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	><path
		d="m39.812 38.154 -14 -30a2 2 0 0 0 -3.624 0l-14 30a2 2 0 0 0 2.6 2.684L24 35.176l13.212 5.662a2 2 0 0 0 2.6 -2.684zm-16.6 -7 -9.184 3.944L24 13.73V31a2.032 2.032 0 0 0 -0.788 0.16z"
		fill={isActive ? activeColor : color}
		stroke-width="2"
	/></svg
>
